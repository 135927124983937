<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

	<b-row class="d-flex justify-content-around mt-2 mb-2">
        <!-- Search -->
        <b-col
          cols="6"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model.lazy="search"
              class="d-inline-block mr-1"
              placeholder="Szukaj..."
            />

          </div>
        </b-col>
    </b-row>

    <b-table
      :items="items"
      :fields="fields"
      primary-key="id"
      show-empty
      empty-text="Brak danych urządzeń"
      class="position-relative"
    >

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
            <b-button variant="primary" class="mr-1" :to="{ name: 'apps-client', params: { id: data.item.client_id } }" v-b-tooltip.hover title="Klient">
              <feather-icon icon="UserIcon" />
            </b-button>
        </div>
      </template>

    </b-table>
    <b-pagination v-model.lazy="page"
        :total-rows="items_count"
        :per-page="limit" first-number last-number prev-class="prev-item" next-class="next-item">
	  <template v-slot:prev-text>
	    <feather-icon icon="ChevronLeftIcon" size="18"/>
	  </template>
	  <template v-slot:next-text>
	    <feather-icon icon="ChevronRightIcon" size="18"/>
	  </template>
	</b-pagination>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BBadge,
  BFormCheckbox, BDropdown, BDropdownItem, BFormSelect, BFormSelectOption,
  BFormDatepicker,
  BFormGroup,
  BPagination
} from 'bootstrap-vue'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BFormSelectOption,
    BFormDatepicker,
    BFormGroup,
    BPagination
  },
  data() {
    return {
      fields: [
        {
          key: 'lp',
          label: 'L.p.',
          sortable: false,
        },
        {
          key: 'id',
          label: 'ID',
          sortable: false,
        },
        {
          key: 'name',
          label: 'Nazwa',
          sortable: false,
        },
        {
          key: 'location',
          label: 'Adres',
          sortable: false,
        },
        {
          key: 'client.firstname',
          label: 'Imię i nazwisko klienta',
          formatter: (value, key, item) => {
              return `${item.client.firstname} ${item.client.lastname}`
          }
        },
        {
          key: 'service_date',
          label: 'Data serwisu',
          sortable: false,
        },
        {
          key: 'installation_date',
          label: 'Data instalacji',
          sortable: false,
        },
        {
          key: 'actions',
          label: 'akcje',
        },
      ],
      items: [],
      search: '',
      limit: 20,
      page: 1,
      items_count: 0
    }
  },
  watch:{
    search: function (value){
      this.fetchFilters()
    },
    page: function(value){
      this.fetchFilters()
    }
  },
  mounted() {
    this.fetchFilters()
    this.fromDate = new Date().toISOString().slice(0, 10)
    this.toDate = new Date().toISOString().slice(0, 10)
  },
  created(){
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
    axios.get(`${process.env.VUE_APP_API_URL}Service`, config).then(response => {
	  this.services = response.data
    }).catch(error => {
      console.log(error)
    })
  },
  methods: {
    fetchFilters() {
      const queryParams = {
        limit: this.limit,
        page: this.page,
      }
      if(this.search){
        queryParams.query = this.search
      }
      const searchParams = new URLSearchParams(queryParams)
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      axios.get(`${process.env.VUE_APP_API_URL}unactive-filters?${searchParams.toString()}`, config).then(response => {
        this.items = response.data.items
        this.items_count = response.data.count
        this.items.map((item, index) => {
          item.lp = index+1
          item.location = `${item.city} ul. ${item.address}`
        })
      }).catch(error => {
        console.log(error)
      })
    },
    deleteFilter(id) {
      axios.delete(`${process.env.VUE_APP_API_URL}Filter/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(() => {
        Swal.fire(
          'Pomyślnie usunięto serwis',
          '',
          'success',
        )
        this.fetchFilters()
      }).catch(() => {
        Swal.fire(
          'Coś poszło nie tak!',
          '',
          'error',
        )
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
